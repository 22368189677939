import React, { useContext } from 'react'

// Context
import TextContext from '../../context/TextContext'

export default function FileItem({ file }) {
    const { files, setFiles, host, key } = useContext(TextContext)

    const downloadFile = (originalname, filename) => {
        fetch(`${host}download-file`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
                filename, key
            }
        })
        .then(d=>d.blob())
        .then(res => {
            const a = document.createElement('a')
            a.href = URL.createObjectURL(res);
            a.setAttribute("download", originalname);
            a.click();
        })
    }

    const deleteFile = (filename) => {
        fetch(`${host}delete-file`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
                filename, key
            }
        })
        .then(d=>d.json())
        .then(res => {
            if(res.status === 200 && res.data) {
                const newFiles = files.filter(file => file.filename !== filename)
                setFiles(newFiles)
            }
        })
    }

    return (
    <div className="download-group text-left">
        <div className="file-title">
            <span>{file.originalname}</span>

            <div className="file-property font-xs">
                <span>{file.time}</span>
                <span>&nbsp; | &nbsp;</span>
                {
                    file.size < 1024 ? <span>{file.size} B</span>
                    :file.size > 1024 && file.size / 1024 < 1024 ? <span>{parseFloat(file.size / 1024).toFixed(2)} KB</span>
                    :file.size / 1024 > 1024 ? <span>{parseFloat(file.size / 1024 / 1024).toFixed(2)} MB</span> : ''
                }
            </div>
        </div>
        <div className="actions flex-center">
            <button onClick={() => downloadFile(file.originalname, file.filename)} className="flex-center mr-s">
                <i className="fa-solid fa-download"></i>
            </button>
            <button onClick={() => deleteFile(file.filename)} className="flex-center">
                <i className="fa-solid fa-trash"></i>
            </button>
        </div> 
    </div>
  )
}

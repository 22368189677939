import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
  return (
    <header>
        <nav className="flex space-between align-center pt-s pb-s">
            <div className="logo">
                <Link to="/">
                    shre<span className="clr-secondary">{">"}</span>in
                </Link>
            </div>

            
            {/* <!-- <div id="main-menu-icon">
                <div>
                    <i onclick="toggleMainMenu()" className="fa-solid fa-chart-bar p-s br-s mr-s clr-primary bg-primary-hover clr-light-hover"></i>
                </div>
                <div className="display-none">
                    <i onclick="toggleMainMenu()" className="fa-solid fa-multiply p-s br-s mr-s clr-primary bg-primary-hover clr-light-hover"></i>
                </div>
            </div> --> */}
            <ul className="primary-nav flex align-center space-between font-600">
                <li><Link to="/contact" className="nav-link font-s contact br-s">Contact</Link></li>
            </ul>
            {/* <!-- <div className="menu-wrapper-p">
                <ul className="flex align-center space-between font-600 mobile-menu">
                    <li><a href="/contact" className="clr-light"><i className="fa-solid fa-bug"></i>&nbsp; Contact</a></li>
                </ul>
            </div> --> */}
        </nav>
    </header>
  )
}

import React, { useContext, useState, useRef } from 'react'

// Context
// import TextContext from '../../context/TextContext';

export default function JoinRoom({ closeRoom }) {
  // const { setIpAddress, setRoomId, setText, host } = useContext(TextContext)
  const inputRoom = useRef(null)

  const joinRoom = () => {
    sessionStorage.setItem('room', inputRoom.current.value)
    window.location.reload()
  }

  const handleInput = (e) => {
    if(e.key === 'Enter') {
      joinRoom()
    }
  }

  return (
    <div className="join-room p-l br-s text-center">
        <div className="close-room" title="Close" onClick={()=>{closeRoom(false);document.title='Shre.IN - Share Text and Files'}}>
            <i className="fa-solid fa-multiply font-m"></i>
        </div>
        <label htmlFor="roomID" className="clr-primary mt-m mb-m font-m font-700">Join a Room</label>
        <p id="room-msg" className="clr-light font-xs text-left">Enter Room ID</p>
        <input ref={inputRoom} onKeyDown={handleInput} type="text" className="mb-l font-s clr-light" name="roomID" id="roomID" placeholder="123 123" autoFocus />
        <button onClick={joinRoom} className="join-btn mb-l btn btn-success" id="joinRoomBtn">
          Join &nbsp;
          <i className="fa-solid fa-right-to-bracket"></i>
        </button>
    </div>
  )
}

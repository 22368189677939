// import { useContext, useEffect } from 'react'

// Importing CSS
import './css/style.css'
import './css/main.css'
import './css/btns.css'
import './css/responsive.css'

// Importing React Router DOM
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// Importing Components
import Home from './components/Home';
import Contact from './components/Contact'
import ErrorPage from './components/ErrorPage'


// Context
import { TextContextProvider } from './context/TextContext';

// Creating Router
const router = createBrowserRouter([
  {
    path: '/',
    element: <Home/>
  },
  {
    path: '/contact',
    element: <Contact/>
  },
  {
    path: '/e',
    element: <ErrorPage/>
  }
]);


function App() {

  return (
    <TextContextProvider>
      <RouterProvider router={router}/>
    </TextContextProvider>)
}
 
export default App;

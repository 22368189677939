import React, { useContext, useEffect } from 'react'

// Context
import TextContext from '../../context/TextContext'

// File Item
import FileItem from './FileItem'
import NoFiles from './NoFiles'

export default function Download({ closeDownload }) {
    const { files, dLoader, setFiles, 
        setDLoader, host, key
    } = useContext(TextContext)

    const downloadAll = () => {
        fetch(`${host}download-all`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              room: sessionStorage.getItem('room'),
              key
            }
        })
        .then(async d => {
            const data = await d.blob()
            if(data.size > 50) {
                return data
            }
        })
        .then(res => {
            if(res !== undefined) {
                const a = document.createElement('a')
                a.href = URL.createObjectURL(res);
                a.setAttribute("download", `Files - ${Date.now()} | Shre.IN.zip`);
                a.click();
            } else {
                alert('No Files Found...')
            }
        })
    }

    const deleteAll = () => {
        const c = window.confirm('Press "OK" to delete files')
        if(c) {
            fetch(`${host}delete-all`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    "Content-Type": "application/json",
                    room: sessionStorage.getItem('room'),
                    key
                }
            })
            .then(d=>d.json())
            .then(res => {
                if(res.data && res.status === 200) {
                    setFiles([])
                }
            })
        }
    }

    const reloadDownload = () => {
        fetch(`${host}download`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
                room: sessionStorage.getItem('room') || null,
                key
            }
        }).then(d=>d.json())
        .then(res => {
            setFiles(res.data)
            setDLoader(false)
        })
    }

    useEffect(() => {
        reloadDownload()
    },[])

    return (
    <div className="download-box flex-center flex-column p-l br-s">
        <div className="close-room" title="Close" onClick={()=>{closeDownload(false);document.title='Shre.IN - Share Text and Files'}}>
            <i className="fa-solid fa-multiply font-m"></i>
        </div>
        <label className="clr-primary mt-m font-m font-700">File Manager</label>

        <div className="file-container mt-l">
            <div className="download-group text-left" style={{backgroundColor: "var(--overlay2)"}}>
                <div className="file-title">
                    <span>
                        Files [ {files.length } ]
                    </span>
                </div>
                <div className="actions flex-center">
                    <button onClick={reloadDownload} className="flex-center mr-l refresh-btn">
                        <i className="fa-solid fa-rotate font-s"></i>
                    </button>

                    <button onClick={downloadAll} className="flex-center mr-s download-all-btn">
                        <i className="fa-solid fa-file-arrow-down font-s"></i>
                    </button>

                    <button onClick={deleteAll} className="flex-center">
                        <i className="fa-solid fa-trash-arrow-up font-s"></i>
                    </button>
                </div> 
            </div>

            {dLoader ?
                <div className='download-grouup flex-center' style={{height: '100px'}}>
                    <span className="loader"></span>
                </div>
                
                :files.length > 0 ?
                files.map(file => <FileItem key={Math.random()*656565} file={file}/>)
                :<NoFiles/>
            }

            <div className="flex-center mt-s clr-danger">
                End of the list!
            </div>
        </div>
    </div>
  )
}

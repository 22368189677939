import React, { useState, useContext, useRef } from 'react'
import axios from 'axios'

// Context 
import TextContext from '../../context/TextContext'

export default function Upload({ closeUpload }) {
    const { host, key } = useContext(TextContext)
    
    const [uploadBox, setUploadBox] = useState(true)
    const [uploadBoxText, setUploadBoxText] = useState('')
    const [uploadError, setUploadError] = useState('')
    const [loader, setLoader] = useState(false)

    const [fileLength, setFileLength] = useState(0)
    
    const handleFile = (e) => {
        let fl = e.target.files.length ;
        setFileLength(fl)

        setUploadBox(false)
        setLoader(false)
        setUploadError('')
        setUploadBoxText(fl + " Files Selected.");
        
        if (fl === 0) {
            setUploadBox(false)
            setLoader(false)
            setUploadError('')
            setUploadBoxText("No File Choosen.");
        }
        
        if(fl > 50) {
            setUploadError(`Allowed : Max File Count 50`)
        }
        
        for(let i = 0; i < fl; i++) {
            if(e.target.files[i].size > 500 * 1024 * 1024) {
                setUploadError('Allowed : Max File Size 500 MB')
            }      
        }
    }

    const fd = useRef(null)

    const uploadFile = () => {
        const formdata = new FormData();

        const filesArr = fd.current.files

        for(let file of filesArr) {
            formdata.append('files', file)
            formdata.append('name', 'files')
        }

        axios.post(`${host}upload`, formdata, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                room : sessionStorage.getItem('room'),
                key
            }
        }).then(r => {
            if(r.data.status === 200 && r.data.data.length > 0) {
                setUploadBoxText(r.data.msg)
                setLoader(false)
                setTimeout(() => {
                    closeUpload(false)
                }, 1000);
            } else {
                setUploadBoxText('Try again! Choose Files...')
                setLoader(false)
                setUploadError(r.data.error)
            }
        })
    }
    


    return (
    <div className="upload-box p-l br-s text-center">
        <div className="close-room" title="Close" onClick={()=>{closeUpload(false);document.title='Shre.IN - Share Text and Files'}}>
            <i className="fa-solid fa-multiply font-m"></i>
        </div>
        <div id="upload-form">
            <label className="clr-primary mt-l font-m font-700">Upload Files</label>

            <input onInput={handleFile} ref={fd} type="file" className="display-none" name="files" id="upload-file" multiple required />
            <label htmlFor="upload-file" style={{position: 'relative'}} className="upload-btn mt-l mb-m" data-set="Choose Files">
                {uploadBox && <span>Choose Files<i className="fa-solid fa-file ml-l"></i></span>}
                {uploadBoxText}
                {loader && <span className="loader"></span>}
            </label>

            <div className="text-left">
                <p className={`clr-danger br-s p-s mt-m mb-m font-09 ${uploadError.length<=0?'display-none':''}`} id="file-upload-error-box">
                    {uploadError}
                </p>
                <p className="clr-darkorange font-xs mt-m">
                    <i className="fa-solid fa-star-of-life clr-danger"></i>
                    &nbsp; File Size Limit : 500 MB & Max Count : 50
                </p>
                <p className="clr-darkorange text-left mb-m font-xs">
                    <i className="fa-solid fa-star-of-life clr-danger"></i>
                    &nbsp; Uploaded files are automatically deleted after 12 AM
                </p>
            </div>

            {fileLength>0 && fileLength<50 &&
            <button onClick={(e=> {
                    setLoader(true);
                    setUploadBox(false);
                    setUploadBoxText('')
                    uploadFile()
                })} className="btn btn-success mb-l" style={{width: "100%"}} id="upload-btn">
                Upload <i className="fa-solid fa-upload ml-m"></i>
            </button>
            }
        </div>
    </div>
  )
}

import React from 'react'

export default function ErrorPage() {
  return (
    <main id="home" className="error-wrapper flex-center" style={{"margin": "3rem auto"}}>
        <div className="error-box br-s" style={{"z-index": "3234"}}>
            <div className="strip flex align-center space-between">
                <div className="error-title">
                    404! Page not found...
                </div>
                <div className="close-btn flex-center">
                    <i className="fa-solid fa-rectangle-xmark" onclick="errorCloseBtn()"></i>
                    {/* <script>
                        const errorCloseBtn = () => {
                            document.querySelector('.error-box').classList.add('display-none')
                        }
                    </script> */}
                </div>
            </div>
            <div className="error-body">
                    <strong>Sorry,</strong>
                    the page you are looking for does not exist.
                    Please check the URL or try again!
            </div>
            <div className="error-btn">
                <a href="/">Return Home</a>
            </div>
        </div>
        <button className="after-error" onclick="history.back()">just go back...</button>
    </main>
  )
}

import React, { useEffect, useRef, useContext, useState } from 'react'

// Header and Footer
import Header from './header/Header'
import Footer from './header/Footer'

// Context
import TextContext from '../context/TextContext'

export default function Contact() {
    const { ipAddress, setIpAddress, host, key } = useContext(TextContext)

    const [errMsg, setErrMsg] = useState('')
    const [msgSent, setMsgSent] = useState('')

    // Form State
    const [nameState, setNameState] = useState('')
    const [mobileState, setMobileState] = useState('')
    const [emailState, setEmailState] = useState('')
    const [subjectState, setSubjectState] = useState('')
    const [messageState, setMessageState] = useState('')


    const [validation, setValidation] = useState({
        name: false,
        mobile: false,
        email: false,
        subject: false,
        message: false
    })

    const name = useRef()
    const mobile = useRef()
    const email = useRef()
    const subject = useRef()
    const message = useRef()

    const handleName = () => {
        if(name.current.value.length <= 0) {
            name.current.style.borderColor = 'red'
            setErrMsg('Name : can not be blank...')

            let v = validation
            v.name = false
            setValidation(v)
        } else if(name.current.value.length < 5) {
            name.current.style.borderColor = 'red'
            setErrMsg('Name : must be atleast 5 character...')

            let v = validation
            v.name = false
            setValidation(v)
        } else {
            name.current.style.borderColor = ''
            setErrMsg('')

            let v = validation
            v.name = true
            setValidation(v)
        }
    }

    const handleEmail = () => {
        if(email.current.value.length <= 0) {
            email.current.style.borderColor = 'red'
            setErrMsg('Email : can not be blank...')

            let v = validation
            v.email = false
            setValidation(v)
        } else if(email.current.value.length < 8) {
            email.current.style.borderColor = 'red'
            setErrMsg('Email : must be atleast 8 character...')

            let v = validation
            v.email = false
            setValidation(v)
        } else if(email.current.value.length > 8) {
            let v = email.current.value.includes('@')
            let dot = email.current.value.includes('.')

            if(v && dot) {
                email.current.style.borderColor = ''
                setErrMsg('')

                let v = validation
                v.email = true
                setValidation(v)
            } else {
                email.current.style.borderColor = 'red'
                setErrMsg('Email : Not valid...')

                let v = validation
                v.email = false
                setValidation(v)
            }
        } else {
            email.current.style.borderColor = ''
            setErrMsg('')

            let v = validation
            v.email = true
            setValidation(v)
        }       
    }

    const handleMobile = () => {
        if(mobile.current.value.length <= 0) {
            mobile.current.style.borderColor = 'red'
            setErrMsg('Mobile : can not be blank...')

            let v = validation
            v.mobile = false
            setValidation(v)
        } else if(mobile.current.value.length < 10) {
            mobile.current.style.borderColor = 'red'
            setErrMsg('Mobile : must be 10 character...')

            let v = validation
            v.mobile = false
            setValidation(v)
        } else if(mobile.current.value.length > 10) {
            mobile.current.style.borderColor = 'red'
            setErrMsg('Mobile : can not be more than 10 character...')

            let v = validation
            v.mobile = false
            setValidation(v)
        } else {
            mobile.current.style.borderColor = ''
            setErrMsg('')

            let v = validation
            v.mobile = true
            setValidation(v)
        }
    }

    const handleSubject = () => {
        if(subject.current.value.length <= 0) {
            subject.current.style.borderColor = 'red'
            setErrMsg('Subject : can not be blank...')

            let v = validation
            v.subject = false
            setValidation(v)
        } else if(subject.current.value.length < 5) {
            subject.current.style.borderColor = 'red'
            setErrMsg('Subject : must atleast be 5 character...')

            let v = validation
            v.subject = false
            setValidation(v)
        } else {
            subject.current.style.borderColor = ''
            setErrMsg('')

            let v = validation
            v.subject = true
            setValidation(v)
        }
    }

    const handleMessage = () => {
        if(message.current.value.length <= 0) {
            message.current.style.borderColor = 'red'
            setErrMsg('Message : can not be blank...')

            let v = validation
            v.message = false
            setValidation(v)
        } else if(message.current.value.length < 5) {
            message.current.style.borderColor = 'red'
            setErrMsg('Message : must atleast be 5 character...')

            let v = validation
            v.message = false
            setValidation(v)
        } else {
            message.current.style.borderColor = ''
            setErrMsg('')

            let v = validation
            v.message = true
            setValidation(v)
        }
    }


    const submitContact = () => {
        handleName()
        handleEmail()
        handleMobile()
        handleSubject()
        handleMessage()

        if( validation.name &&
            validation.mobile &&
            validation.email &&
            validation.subject &&
            validation.message
        ){
            fetch(`${host}contact`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    "Content-Type": "application/json",
                    key
                },
                body: JSON.stringify({
                    name: name.current.value,
                    mobile: mobile.current.value,
                    email: email.current.value,
                    subject: subject.current.value,
                    message: message.current.value,
                    ip: ipAddress
                })
            })
            .then(d=>d.json())
            .then(res => {
                if(res.status === 200) {
                    setMsgSent(res.msg)
                    setTimeout(() => {
                        setMsgSent('')
                    }, 2000);
                    
                    setNameState('')
                    setEmailState('')
                    setMobileState('')
                    setSubjectState('')
                    setMessageState('')

                } else {
                    setErrMsg(res.msg)
                }
            })
        }
    }

    useEffect(() => {
        document.title = 'Contact | Shre.IN'

        if(!ipAddress) {
            fetch(`${host}ip`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    "Content-Type": "application/json",
                    key
                }
            })
            .then(d=>d.json())
            .then(res => {
                if(res.status === 200) {
                    setIpAddress(res.ip)
                }
            })
        }
    }, [])

    return (
    <>
    <Header/>
    
    <main id="home">
        <div className="flex-center flex-column">
            <h1 className="clr-primary">Contact Us</h1>
            <p className="clr-light">Help us, Makes better, Report a bug</p>

            <div className="flex-center flex-column contact-us">
                {errMsg &&
                    <div className='err-msg'>{errMsg}</div>
                }

                {msgSent &&
                    <div className='err-msg clr-light bg-success'>{msgSent}</div>
                }

                <input ref={name} onInput={handleName} onChange={e=>{setNameState(e.target.value)}} value={nameState} type="text" name="name" id="name" placeholder="Name" />

                <div className="flex space-between align-center m-flex-column contact-em">
                    <input ref={mobile} onInput={handleMobile} onChange={e=>{setMobileState(e.target.value)}} value={mobileState} type="number" name="mobile" id="mobile" placeholder="Mobile (+91 ...)" />
                    <input ref={email} onInput={handleEmail} onChange={e=>{setEmailState(e.target.value)}} value={emailState} type="email" name="email" id="email" placeholder="Email" />
                </div>

                <input ref={subject} onInput={handleSubject} onChange={e=>{setSubjectState(e.target.value)}} value={subjectState} type="text" name="subject" id="subject" placeholder="Subject" />
                <textarea ref={message} onInput={handleMessage} onChange={e=>{setMessageState(e.target.value)}} value={messageState} name="message" id="message" placeholder="Message..." ></textarea>

                <p className="clr-light text-left font-xs">Your IP Address : <span className="clr-warning">{ipAddress}</span></p>
                <input type="hidden" name="ip" id="ip" value={ipAddress} />

                <button onClick={submitContact} className="btn btn-success mt-l">
                    Send Message <i className="fa-solid fa-paper-plane ml-s"></i>
                </button>
            </div>
        </div>
        <div className="flex-center">
        </div>
    </main>

    <Footer/>
    </>
  )
}

import React, { useState, useRef, useEffect, useContext } from 'react'

// Header and Footer
import Header from './header/Header'
import Footer from './header/Footer'

// Partials
import Download from './partials/Download'
import JoinRoom from './partials/JoinRoom'
import Upload from './partials/Upload'

// Context
import TextContext from '../context/TextContext'

import io from 'socket.io-client'
const socket = io.connect('https://api.shre.in/')



export default function Home() {
    const { 
        text, setText, domain, host, key,
        ipAddress, setIpAddress, roomId, setRoomId
    } = useContext(TextContext)

    const [joinRoom, setJoinRoom] = useState(false)
    const [upload, setUpload] = useState(false)
    const [download, setDownload] = useState(false)

    const [time, setTime] = useState('')
    const textArea = useRef(null)

    // Capitalized Each Word
    const capitalizeEachWord = () => {
        if(text.length === 0) return

        const newStr = text.toLowerCase().trim().split(/\s+/)
        let strValue = ''

        newStr.forEach(str => {
        strValue += str.replace(str.charAt(0), str[0].toUpperCase()) + ' '
        })
        
        setText(strValue.trim())
        sendText(strValue.trim())
    }

    // UPPERCASE
    const upperCase = () => { 
        setText(text.toUpperCase())
        sendText(text.toUpperCase())
    }

    // lowercase
    const lowercase = () => { 
        setText(text.toLowerCase())
        sendText(text.toLowerCase())
    }
    // Remove Extra Spaces
    const removeExtraSpaces = () => {
        setText(text.replace(/\s+/g,' ').trim())
        sendText(text.replace(/\s+/g,' ').trim())
    }

    // Paste Text
    const pasteText = ()=>{
        textArea.current.focus();
        window.navigator.clipboard.readText()
        .then(d => {
            setText(text + ' ' + d);
            sendText(`${text} ${d}`);
        });
    }

    // Clear Text
    const clearText = () => { 
        setText('')
        sendText('')
    }

    const [charLen, setCharLen] = useState({
        totalSpaces : 0,
        withSpaces : 0, 
        withoutSpaces: 0, 
        totalWord : 0
    })
    
    const charLength = () => {
        let newStr = text.split(/\s+/)
        let strLen = 0
        let totalWord = 0

        newStr.forEach(s => {
            strLen = strLen + s.length
            if(s.trim().length > 0) totalWord++
        })
        
        if(text.length > 0) {
            setCharLen({
            totalSpaces : text.length - strLen,
            withSpaces : text.length, 
            withoutSpaces : strLen, 
            totalWord
            })
        } else {
            setCharLen({
            totalSpaces : 0,
            withSpaces : 0, 
            withoutSpaces : 0, 
            totalWord : 0
            })
        }
    }

    const generateLinks = () => {
        text.split(" ").forEach((s) => {
          const ss = s.split("\n");
          if (ss.length > 1) {
            ss.forEach((sss) => {
              validateLink(sss);
            });
        }
        if (ss.length === 1) {
            validateLink(s);
          }
        });
      };
      
    const validateLink = (link) => {
    const linkContainer = document.querySelector('.link-container')
    
    let http = ["http://", "https://"];
    
    let isValid = false;
    let isHttp = false;
    
    http.forEach((ht) => {
        if (link.includes(ht)) {
        isHttp = true;
        }
    });
    
    domain.forEach(i => {
        i.tlds.forEach(tld => {
            if (link.includes(tld)) {
                isValid = true;
            }
        })
    });
    
    const a = document.createElement("a");
    a.setAttribute("target", "_blank");

    if (link.length > 25) {
        a.innerHTML = link.substring(0, 25) + "...";
        a.setAttribute("title", link);
    } else {
        a.innerHTML = link;
    }
    
    if (!isHttp && isValid) {
        a.setAttribute("href", "http://" + link);
        linkContainer.append(a)
    }

    if (isHttp && isValid) {
        a.setAttribute("href", link);
        linkContainer.append(a)
    }
    };

    const leaveRoom = () => {
        sessionStorage.removeItem('ip')
        sessionStorage.removeItem('room')

        window.location.reload()
    }

    // Get Time
    const getTime = () => {
        const t = Date().substring(16, 24).split(":");
        let h = t[0];
        let amPM = "AM";
    
        if (h > 12) {
        h = h - 12;
        if(h === 11 || h === 10) {
            h = `${h}`;
        } else {
            h = `0${h}`;
        }
        amPM = "PM";
        }
        if(h === 0) {
            h = `12`;
        }
        return `${h} : ${t[1]} : ${t[2]} ${amPM}`;
    };

    // Display Time
    const [displayTime, setDisplayTime] = useState('')
    setTimeout(() => {
        setDisplayTime(getTime())
    }, 1000);
    

    // Emit Event
    const sendText = (txt) => {
        socket.emit('sendText', {
            text: txt,
            room: sessionStorage.getItem('room')
        })
    }

    // Listing to Events
    useEffect(() => {
        // Received Text
        socket.on('receiveText', txt => {
            setText(txt)
        })

        // Updated
        socket.on('updated', t => {
            setTime(t)
        })
    }, [socket])

    useEffect(() => {
        charLength()
        document.querySelector('.link-container').innerHTML = ''
        generateLinks()
    }, [text])

    
    useEffect(() => {
        document.title = 'Shre.IN - Share Text and Files'
        
        const ip = sessionStorage.getItem('ip')
        const room = sessionStorage.getItem('room')

        if(ip === null || room === null) {
            fetch(host, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  key
                }
            })
            .then(d=>d.json())
            .then(res => {
                if(res.status !== 200) {
                    alert(res.msg + ' Please be patient...')
                    return
                } 
                sessionStorage.setItem('room', res.data.room)
                sessionStorage.setItem('ip', res.data.ip)
                
                setIpAddress(res.data.ip)
                setRoomId(res.data.room)
                setText(res.data.text)
                setTime(res.data.time)

                socket.emit("joinRoom", sessionStorage.getItem('room'));
            })
        } else {
            fetch(host, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  room, key
                }
            })
            .then(d=>d.json())
            .then(res => {
                if(res.status !== 200) {
                    alert(res.msg + ' Please be patient...')
                    return
                }
                sessionStorage.setItem('room', res.data.room)
                sessionStorage.setItem('ip', res.data.ip)
                
                setIpAddress(res.data.ip)
                setRoomId(res.data.room)
                setText(res.data.text)
                setTime(res.data.time)

                socket.emit("joinRoom", sessionStorage.getItem('room'));
            })
        }
    }, [])

    return (
    <>
        <Header/>
        <main id="home">
            <section className="flex align-center space-between">
                <div className="share-txt flex align-centers flex-column clr-secondary font-700 font-s">
                    <div>
                        Share Text & Files &nbsp;<i className="fa-solid fa-square-check clr-success"></i>
                    </div>
                    <div style={{
                        fontSize: '.7rem',
                        fontWeight: '500',
                        color: 'var(--grey)',
                        letterSpacing: '.5px'
                    }}>
                        Time <span className='clr-primary'>{">"}</span>
                        &nbsp;{displayTime}
                    </div>
                </div>
                <ul className="flex secondary-menu">
                    <li onClick={()=>{setJoinRoom(true);document.title='Join Room | Shre.IN'}}>
                        <button className="secondary-nav-link cursor-pointer join-room-btn" title="Join a Room to share texts and files privately.">
                            <i className="fa-solid fa-plus"></i> &nbsp;Join Room
                        </button>
                    </li>
                    <li onClick={()=>{setUpload(true);document.title='Upload Files | Shre.IN'}}>
                        <button className="secondary-nav-link cursor-pointer upload-btns" title="Click Upload to upload your files from your device.">
                            <i className="fa-solid fa-upload"></i> &nbsp;Upload
                        </button>
                    </li>
                    <li onClick={() => {
                        setDownload(true);
                        document.title='Download Files | Shre.IN';
                    }}>
                        <button className="secondary-nav-link cursor-pointer download-btn" title="Download all the uploaded files by you and your room members.">
                            <i className="fa-solid fa-download"></i> &nbsp;Download
                        </button>
                    </li>
                    <li>
                        <button onClick={leaveRoom} className="secondary-nav-link cursor-pointer leave-room" title="Leave joined room.">
                            <i className="fa-solid fa-right-from-bracket"></i> &nbsp;Leave Room
                        </button>
                    </li>
                </ul>
                
                <div className="secondary-menu-mobile">
                    <ul className="flex align-center space-between">
                        <li className="text-center" onClick={()=>{setJoinRoom(true);document.title='Join Room | Shre.IN'}}>
                            <button className="join-room-btn  cursor-pointer"><i className="fa-solid fa-plus"></i></button>
                        </li>
                        <li className="text-center" onClick={()=>{setUpload(true);document.title='Upload Files | Shre.IN'}}>
                            <button className="upload-btns  cursor-pointer"><i className="fa-solid fa-upload"></i></button>
                        </li>
                        <li className="text-center" onClick={() => {
                            setDownload(true);
                            document.title='Download Files | Shre.IN';
                        }}>
                            <button className="download-btn  cursor-pointer"><i className="fa-solid fa-download"></i></button>
                        </li>
                        <li className="text-center">
                            <button onClick={leaveRoom} className="leave-room-mobile  cursor-pointer"><i className="fa-solid fa-right-from-bracket"></i></button>
                        </li>
                    </ul>
                </div>
            </section>

            {/* <!-- Join Room Modal --> */}
            <div className={`join-room-wrapper ${joinRoom?'active-menu':''}`}>
                {joinRoom && <JoinRoom closeRoom={setJoinRoom}/>}
            </div>
            
    
            {/* <!-- Upload Modal --> */}
            <div className={`upload-wrapper ${upload?'active-menu':''}`}>
                {upload && <Upload closeUpload={setUpload}/>}
            </div>

            {/* <!-- Download Modal --> */}
            <div className={`download-wrapper ${download?'active-menu':''}`}>
                {download && <Download closeDownload={setDownload} />}
            </div>

            {/* <!-- Link Container ( Clickable Links ) --> */}
            <section>
                <div className="link-container br-s mt-m flex align-center flex-wrap"></div>
            </section>
    
            <section className='mt-m'>
                <div className="font-xs controls flex align-center space-between">
                    <div className="time mr-l">Updated {">"} { time }</div>
                    <div className="controls-large flex align-center justify-center">
                        <button className="copyText" onClick={() => {
                            window.navigator.clipboard.writeText(text)
                        }}>
                            Copy &nbsp;<i className="fa-regular fa-copy"></i>
                        </button>
                        <button className="pasteText" onClick={pasteText}>
                            Paste &nbsp;<i className="fa-regular fa-paste"></i>
                        </button>
                        <button className="clearText" onClick={clearText}>
                            Clear &nbsp;<i className="fa-solid fa-xmark"></i>
                        </button>

                        <div className="edit-btns-large">
                            <button className="uppercase" onClick={upperCase}>
                                UPPERCASE
                            </button>
                            <button className="lowercase" onClick={lowercase}>
                                lowercase
                            </button>
                            <button className="cew" onClick={capitalizeEachWord}>
                                Capitalize Each Word
                            </button>
                            <button className="res" onClick={removeExtraSpaces}>
                                Remove Extra Spaces
                            </button>
                        </div>
                    </div>

                    <div className="controls-mobile">
                        <button className="copyText" onClick={() => { 
                            window.navigator.clipboard.writeText(text)
                        }}>
                            <i className="fa-regular fa-copy"></i>
                        </button>
                        <button className="pasteText" onClick={pasteText}>
                            <i className="fa-solid fa-paste"></i>
                        </button>
                        <button className="clearText" onClick={clearText}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                </div>

                <textarea id="textarea-container" 
                    ref={textArea} 
                    onChange={e => {
                        setText(e.target.value)
                        sendText(e.target.value)
                    }}
                    onBlur={() => {
                        setTime(getTime())
                        socket.emit('updateText', {
                            text: textArea.current.value,
                            room: sessionStorage.getItem('room'),
                            ip: sessionStorage.getItem('ip')
                        })
                    }}
                    className="text-container text clr-light border-s-1 br-s p-s" value={text} autoFocus>
                </textarea>
                <div className="flex space-between align-center summary-container">
                    <button style={{"color": "var(--darkorange)"}}>Total Words : {charLen.totalWord}</button>
                    <button style={{"color": "var(--primary)"}}>Total Characters with Spaces : {charLen.withSpaces}</button>
                    <button style={{"color": "var(--lemongreen)"}}>Total Spaces : {charLen.totalSpaces}</button>
                    <button style={{"color": "var(--warning)"}}>Total Characters without Spaces : {charLen.withoutSpaces}</button>
                </div>
                <div className="controls space-between edit-container">
                    <button className="clr-primary" onClick={upperCase}>
                        UPPERCASE
                    </button>
                    <button className="clr-primary" onClick={lowercase}>
                        lowercase
                    </button>
                    <button className="clr-lemongreen" onClick={capitalizeEachWord}>
                        Capitalize Each Word
                    </button>
                    <button className="clr-darkorange" onClick={removeExtraSpaces}>
                        Remove Extra Spaces
                    </button>
                </div>
            </section>
    
            <section>
                <div className="font-600 mt-l flex align-center space-between">
                    <p className="mb-s">IP Address : <span className="clr-primary">{ipAddress}</span></p>
                    <p className="mb-s">Your Room : <span className="clr-primary">{roomId}</span></p>
                </div>
            </section>
        </main>

        <Footer/>
    </>
  )
}

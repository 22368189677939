import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer id='home' className="flex-center flex-column p-m">
      <div className='contact-footer p-s'>
        <p>Developer <span className='clr-primary'>{">"}</span> Abhishek</p>

        <div className='flex-center'>
          <a href='https://instagram.com/abhimehrr' className='link flex-center' target='_blank' rel="noopener noreferrer">
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a href='mailto:hloo.abhi@gmail.com' className='link flex-center' target='_blank' rel="noopener noreferrer">
            <i className="fa-solid fa-at"></i>
          </a>
          <a href='https://github.com/abhimehrr' className='link flex-center' target='_blank' rel="noopener noreferrer">
            <i className="fa-brands fa-github"></i>
          </a>
        </div>
      </div>

      <span  className='flex-center br-s mt-s'>
        &copy;  &nbsp; <span id="copy-year">{new Date().getFullYear()}</span>
        &nbsp; Shre.IN - Share Text & Files
      </span>
    </footer>
  )
}
